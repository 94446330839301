import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import MonochromeLogo from '../MonochromeLogo';
import { graphql, Link, useStaticQuery } from 'gatsby';

const FooterContainer = styled.div`
  background-color: #101010;
  text-align: center;
  width: 100%;
  margin-bottom: ${rem('50px')};
`;

const LinksContainer = styled.div`
  width: 100%;
`;

const FooterLink = styled(Link)`
  color: #fff;
  padding: 1rem;
  width: 100%;
  @media (max-width: ${props => props.theme.mdWidth}) {
    display: block;
    padding: 0.5rem;
  }
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const FooterExternalLink = styled.a`
  color: #fff;
  padding: 1rem;
  width: 100%;
  @media (max-width: ${props => props.theme.mdWidth}) {
    display: block;
    padding: 0.5rem;
  }
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const Footer = () => {
  const { homePageContent } = useStaticQuery(graphql`
    query {
      homePageContent {
        phoneNumber
        phoneNumberCall
        emailAddress
      }
    }
  `);
  return (
    <FooterContainer>
      <Link to="/">
        <MonochromeLogo />
      </Link>
      <LinksContainer>
        <FooterLink to="/about">About</FooterLink>
        {homePageContent.phoneNumber && homePageContent.phoneNumberCall && (
          <FooterExternalLink href={`tel:+1${homePageContent.phoneNumberCall}`}>
            {homePageContent.phoneNumber}
          </FooterExternalLink>
        )}
        {homePageContent.emailAddress && (
          <FooterExternalLink href={`mailto:${homePageContent.emailAddress}`}>
            {homePageContent.emailAddress}
          </FooterExternalLink>
        )}
      </LinksContainer>
    </FooterContainer>
  );
};

export default Footer;
