import React from 'react';
import PropTypes from 'prop-types';
import NormalPanelContainer from './NormalPanelContainer';
import styled from 'styled-components';

const Container = styled(NormalPanelContainer)`
  clear: both;
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

const NormalPanel = props => {
  return (
    <Container
      backgroundColor={props.backgroundColor}
      bgImage={props.backgroundImage}
      fullScreen={props.fullScreen}
    >
      {props.children}
    </Container>
  );
};

NormalPanel.defaultProps = {
  backgroundColor: '#101010',
  backgroundImage: null,
  fullScreen: true,
};

NormalPanel.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.any,
  fullScreen: PropTypes.bool,
};

export default NormalPanel;
