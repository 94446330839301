import styled from 'styled-components';

const Nav = styled.nav`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: -100vw;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: -1;
  pointer-events: none;
  opacity: 1;
  color: #fff;
  padding-top: 11rem;
  text-align: right;
  // Transitions
  transition: left 500ms;
  @media (max-width: ${props => props.theme.mdWidth}) {
    text-align: left;
  }
  @media (min-width: ${props => props.theme.lgWidth}) {
    transition: left 400ms;
  }
  @media (min-width: ${props => props.theme.smWidth}) and (max-width: ${props =>
      props.theme.lgWidth}) and (orientation: landscape) {
    padding-top: 3rem;
  }
  ${props =>
    props.open &&
    `
    pointer-events: all;
    opacity: 1;
    left: 0;
  `};
  @media (orientation: landscape) and (max-device-width: ${props => props.theme.lgWidth}) {
   text-align: center;
  }
`;

export default Nav;
