import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/index.scss';

const GlobalStyle = createGlobalStyle`
  html {
    background-color: #101010;
  }
  body {
    background-color: #101010 !important;
  }
`;

const theme = {
  smWidth: '576px',
  mdWidth: '768px',
  lgWidth: '992px',
  xlWidth: '1200px',
  xxlWidth: '1400px',
};

const CoreLayout = ({ children }) => {
  const [scrolled, setScrolledStatus] = React.useState(true);
  const [menuIsOpen, setMenuStatus] = React.useState(false);

  React.useEffect(() => {
    if (menuIsOpen) {
      document.body.classList.add('stop-scrolling');
      document.ontouchmove = e => {
        e.preventDefault();
      };
    } else {
      document.body.classList.remove('stop-scrolling');
      document.ontouchmove = () => true;
    }
  }, [menuIsOpen]);

  const updateBody = React.useCallback(() => {
    if (menuIsOpen) {
      setScrolledStatus(true);
    } else {
      if (window.pageYOffset >= 150) {
        setScrolledStatus(true);
      } else if (window.pageYOffset < 150) {
        setScrolledStatus(false);
      }
    }
  }, [menuIsOpen]);

  React.useEffect(updateBody, []);

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      updateBody();
    });
    window.addEventListener('load', () => {
      updateBody();
    });
  }, [updateBody]);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          metadata {
            title: metaTitle
          }
        }
      `}
      render={({ metadata }) => [
        <GlobalStyle key={0} />,
        <ThemeProvider key={1} theme={theme}>
          <Header
            menuIsOpen={menuIsOpen}
            setMenuStatus={setMenuStatus}
            showLogo={!scrolled}
            siteTitle={metadata.title}
          />
        </ThemeProvider>,
        <ThemeProvider key={2} theme={theme}>
          <main>{children}</main>
        </ThemeProvider>,
        <ThemeProvider key={3} theme={theme}>
          <Footer />
        </ThemeProvider>,
      ]}
    />
  );
};

CoreLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoreLayout;
