import styled from 'styled-components';
import { Link } from 'gatsby';
import ListItem from './ListItem';

const NavLink = component => styled(component)`
  color: #e1e1e1;
  padding: 3rem;
  opacity: 0.8;
  @media (max-width: ${props => props.theme.mdWidth}) {
    padding-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.lgWidth}) {
    opacity: 0.8;
  }
  ${ListItem}:hover & {
    transform: translateX(20px);
  }
  &:hover {
    opacity: 1;
    cursor: pointer;
    text-decoration: none !important;
    color: #fff;
  }
`;

export const NavLinkSpan = NavLink('span');

export const NavLinkAnchor = NavLink('a');

export const NavGatsbyLink = NavLink(Link);

export default NavLink;
