import React from 'react';
import PropTypes from 'prop-types';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { scroller } from 'react-scroll';
import UnorderedList from './UnorderedList';
import NavItem from './NavItem';
import Nav from './Nav';

const NavigationMenu = ({ open, toggleMenu }) => {
  const { homePageContent } = useStaticQuery(graphql`
    query {
      homePageContent {
        phoneNumber
        phoneNumberCall
        emailAddress
      }
    }
  `);
  return (
    <Nav open={open}>
      <UnorderedList>
        <NavItem to="/" num={0} open={open}>
          Home
        </NavItem>
        <NavItem
          to={() => {
            if (window.location.pathname === '/') {
              const width = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
              console.log(width);
              if (width <= 992) {
                scroller.scrollTo('leadFormPanel', {
                  smooth: true,
                  duration: 500,
                });
              }
              toggleMenu();
            } else {
              navigate('/', {
                state: {
                  toLeadPanel: true,
                },
              });
            }
          }}
          num={1}
          open={open}>
          Book Now
        </NavItem>
        <NavItem to="/about" num={2} open={open}>
          About Us
        </NavItem>
        {homePageContent.phoneNumber && homePageContent.phoneNumberCall && (
          <NavItem to={`tel:+1${homePageContent.phoneNumberCall}`} num={3} open={open}>
            {homePageContent.phoneNumber}
          </NavItem>
        )}
        {homePageContent.emailAddress && (
          <NavItem to={`mailto:${homePageContent.emailAddress}`} num={4} open={open} small={true}>
            E-Mail Us
          </NavItem>
        )}
      </UnorderedList>
    </Nav>
  );
};

NavigationMenu.defaultProps = {
  open: false,
  toggleMenu: () => {},
};

NavigationMenu.propTypes = {
  open: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
};

export default NavigationMenu;
