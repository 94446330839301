import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import NavigationMenu from '../NavigationMenu';
import HamburgerMenu from '../HamburgerMenu';
import HeaderContainer from './HeaderContainer';
import Logo from './Logo';
import Phone from './Phone';
import './Header.scss';

class Header extends React.Component {
  toggleMenu = () => {
    const { menuIsOpen, setMenuStatus } = this.props;
    setMenuStatus(!menuIsOpen);
  };

  hamburger = () => {
    const { menuIsOpen } = this.props;
    return <HamburgerMenu className="burger" burgerStyle="emphatic" active={menuIsOpen} onClick={this.toggleMenu} />;
  };

  render() {
    const { menuIsOpen, showLogo } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query PhoneNumberQuery {
            homePageContent {
              phoneNumberCall
            }
            logo: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
          }
        `}
        render={data => {
          const { phoneNumberCall } = data.homePageContent;
          return (
            <HeaderContainer className="navbar fixed-top">
              <nav>
                <Logo open={menuIsOpen} hide={!showLogo} img={data.logo.childImageSharp.fixed} />
                {this.hamburger()}
                {!menuIsOpen && phoneNumberCall && (
                  <Phone
                    onClick={() => {
                      window.location = `tel:${phoneNumberCall}`;
                    }}>
                    <FontAwesomeIcon icon={faPhone} flip="horizontal" size="2x" />
                  </Phone>
                )}
              </nav>
              <NavigationMenu open={menuIsOpen} toggleMenu={this.toggleMenu} />
            </HeaderContainer>
          );
        }}
      />
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  showLogo: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: '',
  showLogo: true,
};

export default Header;
