import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Img from 'gatsby-image/withIEPolyfill';
import { Link } from 'gatsby';

export const LogoContainer = styled.div`
  min-width: ${rem('200px')};
  opacity: 1;
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  -ms-transition: opacity 400ms;
  -o-transition: opacity 400ms;
  transition: opacity 400ms;
  max-width: 10rem;
  img {
    z-index: 1070;
  }
  @media (max-width: ${props => props.theme.lgWidth}) {
    margin: auto;
  }
  @media (min-width: ${props => props.theme.smWidth}) and (max-width: ${props =>
  props.theme.lgWidth}) and (orientation: landscape) {
    margin-top: -1rem;
    margin-left: 0;
    transform: scale(0.8);
  }
  ${props =>
  !props.open &&
  `
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    -ms-filter: dropShadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
  `};
  ${props => !props.open && props.hide && `
    opacity: 0;
    pointer-events: none;
  `};
`;

const Logo = props => (
  <LogoContainer id="logo" open={props.open} hide={props.hide}>
    <Link to="/">
      <Img fixed={props.img} alt="I Want Lasers" objectFit="contain" />
    </Link>
  </LogoContainer>
);

export default Logo;
