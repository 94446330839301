import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

const Container = styled.div`
  margin: 2.2rem auto;
`;

const MonochromeLogo = () => {
  const data = useStaticQuery(graphql`
    query ChromeLogo {
      chromeLogo: file(relativePath: { eq: "logo_chrome.png" }) {
        childImageSharp {
          fixed(quality: 60, width: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Img fixed={data.chromeLogo.childImageSharp.fixed} alt="I Want Lasers" />
    </Container>
  );
};

export default MonochromeLogo;
