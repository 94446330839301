import styled from 'styled-components';
import { rem } from 'polished';

const ListItem = styled.li`
  padding: 3rem;
  opacity: 0;
  transform: translateX(${rem('-50px')});
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  width: 100%;
  list-style-type: none;
  transition: transform ${props => 500 + props.num * 200}ms, opacity ${props => 500 + props.num * 200}ms;
  ${props =>
    props.open &&
    `
    transition-delay: ${150 + props.num * 100}ms;
    opacity: 1;
    transform: translateX(0);
  `};
  @media (max-width: ${props => props.theme.lgWidth}) {
    padding: 0 0 5rem 0;
    opacity: 0;
    transition: transform ${props => 100 + props.num * 100}ms, opacity ${props => 200 + props.num * 100}ms;
    transform: translateX(${rem('-50px')});
    ${props =>
      props.open &&
      `
      transition-delay: ${120 + props.num * 50}ms;
      opacity: 1;
      transform: translateX(0);
    `};
  }
  @media (max-width: ${props => props.theme.mdWidth}) {
    padding: 0 0 2rem 0;
  }
  &:hover {
    opacity: 1;
  }
  @media (orientation: landscape) and (max-device-width: ${props => props.theme.lgWidth}) {
    padding: 0 0 0.7rem 0;
  }
`;

export default ListItem;
