import styled from 'styled-components';

const Phone = styled.div`
  color: #fff;
  bottom: 0;
  margin: 4rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0.675rem 1.25rem 0.675rem 1.25rem;
  position: fixed;
  left: 0;
  z-index: 1070;
  @media (min-width: ${props => props.theme.mdWidth}) {
    display: none;
  }
  .label {
    font-family: Exo, sans-serif;
    font-size: 2rem;
    padding-left: 1rem;
  }
`;

export default Phone;
