import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noSelect } from '../../styles/mixins';

const hamburgers = {
  '3dx': 'hamburger--3dx',
  '3dx_reverse': 'hamburger--3dx-r',
  '3dy': 'hamburger--3dy',
  '3dy_reverse': 'hamburger--3dy-r',
  '3dxy': 'hamburger--3dxy',
  '3dxy_reverse': 'hamburger--3dxy-r',
  arrow: 'hamburger--arrow',
  arrow_reverse: 'hamburger--arrow-r',
  arrowalt: 'hamburger--arrowalt',
  arrowalt_reverse: 'hamburger--arrowalt-r',
  arrowturn: 'hamburger--arrowturn',
  arrowturn_reverse: 'hamburger--arrowturn-r',
  boring: 'hamburger--boring',
  collapse: 'hamburger--collapse',
  collapse_reverse: 'hamburger--collapse-r',
  elastic: 'hamburger--elastic',
  'elastic-r': 'hamburger--elastic-r',
  emphatic: 'hamburger--emphatic',
  emphatic_reverse: 'hamburger--emphatic-r',
  minus: 'hamburger--minus',
  slider: 'hamburger--slider',
  slider_reverse: 'hamburger--slider-r',
  spin: 'hamburger--spin',
  spin_reverse: 'hamburger--spin-r',
  spring: 'hamburger--spring',
  spring_reverse: 'hamburger--spring-r',
  stand: 'hamburger--stand',
  stand_reverse: 'hamburger--stand-r',
  squeeze: 'hamburger--squeeze',
  vortex: 'hamburger--vortex',
  vortex_reverse: 'hamburger--vortex-r',
};

const HamburgerMenu = props => {
  const { reverse, active, className, onClick } = props;
  let { burgerStyle } = props;

  if (reverse) burgerStyle = `${burgerStyle}_reverse`;

  const classList = classnames('hamburger', hamburgers[burgerStyle], { 'is-active': active }, className);

  return (
    <button className={classList} onClick={onClick} type="button">
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

HamburgerMenu.propTypes = {
  style: PropTypes.oneOf(Object.keys(hamburgers).map(hamburger => !hamburger.endsWith('_reverse'))),
  reverse: PropTypes.bool,
  active: PropTypes.bool,
};

HamburgerMenu.defaultProps = {
  reverse: false,
  active: false,
};

export default styled(HamburgerMenu)`
  ${props => noSelect()}
`;
