import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ description, lang, meta, keywords, title }) => {
  const { metadata, socialMedia } = useStaticQuery(
    graphql`
      query {
        metadata {
          description: metaDescription
          imageUrl: metaImageUrl
          title: metaTitle
          keywords: metaKeywords
        }
        socialMedia: socialMediaAccounts {
          instagramUsername
          facebookPageName
          twitterUsername
          facebookAppId
          twitterUserId
        }
      }
    `,
  );

  const metaDescription = description || metadata.description || '';

  const metaContent = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:image`,
      content: metadata.imageUrl,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: socialMedia.twitterUsername,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: 'viewport',
      content: 'width=device-width, user-scalable=no',
    },
  ];
  let keywordsList = [];
  if (metadata.keywords.length > 0) {
    keywordsList = keywordsList.concat(metadata.keywords);
  }
  if (keywords.length > 0) {
    keywordsList = keywordsList.concat(keywords);
  }
  metaContent.concat({
    name: 'keywords',
    content: keywordsList.join(', '),
  });
  metaContent.concat(meta);

  return <Helmet htmlAttributes={{ lang }} title={title} titleTemplate={`%s - ${metadata.title}`} meta={metaContent} />;
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
