import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

const NormalContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 0;
  display: flex;
  @media (min-width: 768px) {
    padding: 1rem;
  }
  ${props => {
    if (props.fullScreen) {
      return `
        min-height: 100vh;
      `;
    }  
  }};
`;

NormalContainer.propTypes = {
  backgroundColor: PropTypes.string,
  fullScreen: PropTypes.bool,
};

NormalContainer.defaultProps = {
  backgroundColor: '#101010',
  fullScreen: true,
};

const BgImage = styled(BackgroundImage)`
  padding: 0;
  @media (min-width: 768px) {
    padding: 1rem;
  }
  &:before,
  &:after {
    opacity: 0.2 !important;
  }
  display: flex;
  ${props => {
    if (props.fullScreen) {
      return `
        height: 100vh;
      `
    }
  }}
`;

const NormalPanelContainer = props => {
  const { bgImage, backgroundColor, children, fullScreen } = props;
  if (bgImage !== null) {
    const style = {
      // Defaults are overwrite-able by setting one or each of the following:
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    };
    return (
      <BgImage
        Tag="div"
        fluid={bgImage}
        preserveStackingContext={true}
        backgroundColor={backgroundColor}
        classId="NormalPanelContainer"
        style={style}
        fullScreen={fullScreen}
      >
        {children}
      </BgImage>
    );
  }
  return <NormalContainer backgroundColor={backgroundColor} fullScreen={fullScreen}>{children}</NormalContainer>;
};

NormalPanelContainer.propTypes = {
  backgroundColor: PropTypes.string,
  bgImage: PropTypes.any,
  fullScreen: PropTypes.bool,
};

NormalPanelContainer.defaultProps = {
  backgroundColor: '#101010',
  bgImage: null,
  fullScreen: true,
};

export default NormalPanelContainer;
