import React from 'react';
import PropTypes from 'prop-types';
import { NavGatsbyLink, NavLinkAnchor, NavLinkSpan } from './NavLink';
import ListItem from './ListItem';

const NavItem = ({ to, children, num, open, small }) => {
  let link = null;
  if (typeof to === 'function') {
    link = (
      <NavLinkSpan onClick={to} small={small}>
        {children}
      </NavLinkSpan>
    );
  } else if (typeof to === 'string') {
    if (to.match(/^https?:\/\//i)) {
      link = (
        <NavLinkAnchor href={to} target="_blank" rel="noopener noreferrer" small={small}>
          {children}
        </NavLinkAnchor>
      );
    } else if (to.match(/^(tel:|mailto:)/gim)) {
      link = (
        <NavLinkAnchor href={to} rel="noopener noreferrer" small={small}>
          {children}
        </NavLinkAnchor>
      );
    } else {
      link = (
        <NavGatsbyLink to={to} small={small}>
          {children}
        </NavGatsbyLink>
      );
    }
  }
  return (
    <ListItem num={num} open={open}>
      {link}
    </ListItem>
  );
};

NavItem.defaultProps = {
  to: '/',
  children: null,
};

NavItem.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  children: PropTypes.node,
};

export default NavItem;
